import * as types from "./types";
import api from "../../api";
import { getDistrictsPrices } from "../shedule/actions";

export const getAllSpecializations = (perPage) => (dispatch) => {
  return api.specializations.getAllSpecializations(perPage).then((res) => {
    return dispatch({
      type: types.GET_ALL_SPECIALIZATIONS,
      ...res,
    });
  });
};

export const getSpecializationsByFilter = (filter) => (dispatch) => {
  return api.specializations.getAllSpecializations(100).then((res) => {
    let filterItems = res.items.filter((el) => filter.includes(el.id));
    return dispatch({
      type: types.GET_ALL_SPECIALIZATIONS,
      ...res,
      items: filterItems,
    });
  });
};

export const updateHomePrices =
  (specialtyId, provinceId, requestBody) => async (dispatch) => {
    await api.specializations.updateHomePrices(
      specialtyId,
      provinceId,
      requestBody
    );
    dispatch(getDistrictsPrices(specialtyId, provinceId));
  };

export const editSpecialization =
  ({ id, ...data }) =>
  (dispatch) => {
    delete data.photo;
    delete data.imageUrl;
    delete data.formatType;
    let formData = {
      specialty: {
        ...data,
        detail: [{ price: +data.priceOnline }],
      },
    };
    delete formData.specialty.priceOnline;
    delete formData.specialty.priceHome;
    delete formData.specialty.keywordsTranslations;
    delete formData.specialty.descriptionTranslations;
    delete formData.specialty.titleTranslations;
    delete formData.specialty.priceHome;
    delete formData.specialty.priceOnline;
    delete formData.specialty.firebaseEventName;
    return api.specializations
      .updateSpecializations(id, formData)
      .then((res) => res);
  };

export const addSpecialization = (data) => (dispatch) => {
  let formData = {
    specialty: {
      ...data,
      detail: [{ price: +data.priceOnline }],
    },
  };
  delete formData.specialty.keywordsTranslations;
  delete formData.specialty.descriptionTranslations;
  delete formData.specialty.titleTranslations;
  delete formData.specialty.photo;
  delete formData.specialty.priceHome;
  delete formData.specialty.priceOnline;

  return api.specializations.addNewSpecializations(formData).then((res) => res);
};

export const deleteSpecializations = (id) => (dispatch) => {
  return api.specializations
    .deleteSpecializations(id)
    .then((res) => dispatch({ type: "DELETE_SPECIALIZATIONS", res: res }));
};

export const setPagination =
  ({ numberOfPage }) =>
  (dispatch) => {
    return api.specializations
      .getAllSpecializations(null, numberOfPage)
      .then((res) => {
        dispatch({
          type: types.GET_ALL_SPECIALIZATIONS,
          ...res,
        });
      });
  };
